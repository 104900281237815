import dayjs from "dayjs";
import { useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  TextInput,
  useListContext,
  Pagination,
  useRecordContext,
  useUpdate,
  useRefresh,
  Button,
  Confirm,
} from "react-admin";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";

const MarkAsProcessedButton = () => {
  const record = useRecordContext();
  const [open, setOpen] = useState(false);
  const [update, { isLoading, error }] = useUpdate();
  const refresh = useRefresh();

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = () => {
    update(
      "one-time-passcode",
      { id: record.id, previousData: record },
      { mutationMode: "pessimistic" }
    );
    setOpen(false);
    refresh();
  };
  if (error) {
    return <p>ERROR</p>;
  }

  return (
    <>
      <Button
        label="Extend"
        children={<KeyboardDoubleArrowUpIcon />}
        disabled={isLoading || record.status === "PROCESSED"}
        onClick={handleClick}
      />
      <Confirm
        isOpen={open}
        loading={isLoading}
        title={`Passcode - ${record.id}`}
        content={`Are you sure you want to extend the verification code - ${record.verifyCode}?`}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

export const OneTimePasscode = () => {
  const ListPagination = () => {
    const { data } = useListContext();
    if (!data?.length) return null;
    return <Pagination rowsPerPageOptions={[5, 10, 25, 50]} />;
  };

  const filters = [<TextInput label="Search" source="q" alwaysOn />];

  return (
    <List
      perPage={25}
      filters={filters}
      pagination={<ListPagination />}
      actions={null}
    >
      <Datagrid rowClick={null} bulkActionButtons={false}>
        <TextField label="ID" source="id" />
        <TextField label="Email" source="email" />
        <TextField label="Type" source="type" />
        <TextField label="Verify Code" source="verifyCode" />
        <FunctionField
          label="CreatedAt"
          render={(record) =>
            dayjs(record.createdAt).format("DD/MM/YYYY HH:mm")
          }
        />
        <MarkAsProcessedButton />
      </Datagrid>
    </List>
  );
};
